import React from 'react'

const RSVP = () => {
  return (
    <div className='waiting_list_page'>
        <div className='card-container'>
            RSVP
        </div>
    </div>
  )
}

export default RSVP